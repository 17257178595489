<template>
  <v-container fluid class="text-center">
    <v-card class="elevation-5 mt-5 mx-5">
      <v-toolbar dense dark color="secondary" elevation="2">
        <v-toolbar-title>
          Plantas
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text small @click="dialogs.new = true">
          Agregar
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title>
        <v-btn small :loading="loading" elevation="0" color="secondary" outlined class="mx-2" @click="refresh()">
          Actualizar
          <v-icon right>mdi-refresh</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </v-card-title>
      <v-data-table v-model="selected" :headers="headers" :items="items" :loading="loading" :search="search"
      :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="10" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item)">mdi-pencil</v-icon></template><span>Editar</span></v-tooltip>
          <v-tooltip top><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="warning" dark v-bind="attrs" v-on="on" :disabled="loading" @click="viewMap(item)">mdi-eye</v-icon></template><span>Mostrar en mapa</span></v-tooltip>
          <v-tooltip right><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item.idplanta)">mdi-delete</v-icon></template><span>Eliminar</span></v-tooltip>
        </template>
        <template v-slot:[`footer.page-text`]="props">
          Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
        </template>
        <template v-slot:no-data>
          No hay información disponible
        </template>
        <template v-slot:no-results>
          No se obtuvieron resultados
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogs.new" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Nueva planta</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="save()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formNew" lazy-va2lidation>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-autocomplete v-model="forms.new.empresa" :items="catalogues.empresas" item-text="nombre" item-value="idempresa" label="Empresa" :rules="rules.empresa" :disabled="loading" dense outlined persistent-hint></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete v-model="forms.new.zona" :items="catalogues.zonas" item-text="nombre" item-value="idzona" label="Zona" :rules="rules.zona" :disabled="loading" dense outlined persistent-hint></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.new.nombre" label="Nombre" :rules="rules.nombre" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.new.codigo" label="Codigo" :rules="rules.codigo" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.new.latitud" label="Latitud" :rules="rules.latitud" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.new.longitud" label="Longitud" :rules="rules.longitud" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.new.cuadrante" label="Cuadrante" :rules="rules.cuadrante" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.update" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Actualizar planta</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-autocomplete v-model="forms.update.empresa" :items="catalogues.empresas" item-text="nombre" item-value="idempresa" label="Empresa" :rules="rules.empresa" :disabled="loading" dense outlined persistent-hint></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete v-model="forms.update.zona" :items="catalogues.zonas" item-text="nombre" item-value="idzona" label="Zona" :rules="rules.zona" :disabled="loading" dense outlined persistent-hint></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.update.nombre" label="Nombre" :rules="rules.nombre" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.update.codigo" label="Codigo" :rules="rules.codigo" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.update.latitud" label="Latitud" :rules="rules.latitud" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.update.longitud" label="Longitud" :rules="rules.longitud" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.update.cuadrante" label="Cuadrante" :rules="rules.cuadrante" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.remove" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea eliminar la planta?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()">
            Cancelar
          </v-btn>
          <v-btn text small :loading="loading" color="error" @click="remove()">
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import services from '@/utils/services'

export default {
  name: 'plant',
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    search: '',
    headers: [
      { text: 'Empresa', align: 'left', sortable: false, value: 'empresa.nombre' },
      { text: 'Zona', align: 'left', sortable: false, value: 'zona.nombre' },
      { text: 'Nombre', align: 'left', sortable: false, value: 'nombre' },
      { text: 'Código', align: 'left', sortable: false, value: 'codigo' },
      { text: 'Acciones', align: 'left', sortable: false, value: 'actions' },
    ],
    items: [],
    selected: [],
    deleted: null,
    catalogues: {
      empresas: [],
      zonas: []
    },
    forms: {
      new: {
        empresa: '',
        zona: '',
        nombre: '',
        codigo: '',
        latitud: '',
        longitud: '',
        cuadrante: ''
      },
      update: {
        idplanta: '',
        empresa: '',
        zona: '',
        nombre: '',
        codigo: '',
        latitud: '',
        longitud: '',
        cuadrante: ''
      }
    },
    rules: {
      empresa: [
        v => !!v || 'El campo es requerido'
      ],
      zona: [
        v => !!v || 'El campo es requerido'
      ],
      nombre: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 50) || 'El campo excede la longitud máxima'
      ],
      codigo: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 50) || 'El campo excede la longitud máxima'
      ],
      latitud: [
        v => !!v || 'El campo es requerido',
        v => /^\-?\d+\.\d+?$/.test(v) || 'El campo es inválido'
      ],
      longitud: [
        v => !!v || 'El campo es requerido',
        v => /^\-?\d+\.\d+?$/.test(v) || 'El campo es inválido'
      ],
      cuadrante: [
        v => !!v || 'El campo es requerido',
        v => (/^\d{1,10}$/.test(v) && parseInt(v) >= 0 && parseInt(v) <= 9999999999) || 'El campo es inválido'
      ]
    },
    dialogs: {
      new: false,
      update: false,
      remove: false
    }
  }),
  mounted() {
    this.refresh()
  },
  methods: {
    async refresh() {
      this.loading = true
      const company = await this.axios.get(services.routes.company)
      if (company.data.data) {
        this.catalogues.empresas = company.data.data
      }
      const zone = await this.axios.get(services.routes.zone)
      if (zone.data.data) {
        this.catalogues.zonas = zone.data.data
      }
      this.axios.get(services.routes.plant)
        .then(response => {
          this.items = response.data.data
        })
        .catch(error => {})
        .finally(() => {
          this.loading = false
        })
    },
    cancel() {
      if (this.dialogs.new) {
        this.$refs.formNew.reset()
        for (let key in this.forms.new) {
          this.forms.new[key] = ''
        }
        this.dialogs.new = false
      }
      else if (this.dialogs.update) {
        this.$refs.formUpdate.reset()
        for (let key in this.forms.update) {
          this.forms.update[key] = ''
        }
        this.dialogs.update = false
      }
      else if (this.dialogs.remove) {
        this.dialogs.remove = false
      }
    },
    save() {
      if (this.$refs.formNew.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.forms.new))
        data.ubicacion = {
          type: "Point",
          coordinates: [data.latitud, data.longitud]
        }
        this.axios.post(services.routes.plant, { data })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Planta creada exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    view(data) {
      this.forms.update = {
        idplanta: data.idplanta,
        empresa: data.empresa.idempresa,
        zona: data.zona.idzona,
        nombre: data.nombre,
        codigo: data.codigo,
        latitud: data.ubicacion.coordinates[0],
        longitud: data.ubicacion.coordinates[1],
        cuadrante: data.cuadrante
      }
      this.dialogs.update = true
    },
    viewMap(data) {
      const link = `http://maps.google.com/?q=${data.ubicacion.coordinates[0]},${data.ubicacion.coordinates[1]}`
      window.open(link, '_blank')
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.forms.update))
        data.ubicacion = {
          type: "Point",
          coordinates: [data.latitud, data.longitud]
        }
        this.axios.put(services.routes.plant, { data })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Planta actualizada exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    remove(id) {
      if (!id) {
        this.loading = true
        this.axios.delete(services.routes.plant, { data: { data: { idplanta: this.deleted } } })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Planta eliminada exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.deleted = id
        this.dialogs.remove = true
      }
    }
  }
}
</script>

<style scoped>
</style>
